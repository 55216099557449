class MobileNav {
	constructor() {
		this.toggle = document.querySelector(".toggle")
		this.links = document.querySelector(".overlayChild")
		this.dropdowns = document.querySelectorAll(".mobile_links li.dropdown")
		this.backBtns = document.querySelectorAll(".back")
		this.clickLinks = this.links.querySelectorAll('a:not(.dropdown-toggle)')

		this.init()
	}

	subClicks() {
		this.dropdowns.forEach(dropdown => {
			let linked = dropdown.querySelector('a')
			linked.addEventListener('click', function(e) {
				openChildNav(dropdown)
			} )
		})
	}

	navigate(link) {
		//let url = link.data('href')
		link.click()
	}

	toggleNav() {
		if(this.toggle.classList.contains('is-transitioning')) {
			return
		} else {
			this.toggle.classList.add('is-transitioning')
			this.toggle.classList.toggle('active')
			document.body.classList.toggle('noscroll')
			this.links.classList.toggle('active')
			this.dropdowns.forEach(dropdown => {
				dropdown.classList.remove('open')
			})
			var that = this
			setTimeout(function() {
				that.toggle.classList.remove('is-transitioning')
			}, 500)
		}
	}

	setupChildNav() {
		this.dropdowns.forEach(dropdown => {
			let links = dropdown.querySelector('ul'),
			dropdownID = dropdown.id
			let back_btn = document.createElement("li")
			back_btn.setAttribute('class', 'back')
			back_btn.setAttribute('data-dropid', dropdownID)
			back_btn.setAttribute('role', 'menuitem')
			links.append(back_btn)
			back_btn.innerHTML = back_btn.innerHTML + 'Back'
			this.updateBackBtns()
		})
	}

	updateBackBtns() {
		this.backBtns = document.querySelectorAll(".back")
	}

	toggleChildNav(dropdown, e) {	
		dropdown.classList.add('open')
	}

	toggleChildNavBack(backBtn) {
		let backID = backBtn.dataset.dropid,
			menuItem = document.querySelector('#' + backID)
		menuItem.classList.remove('open')
	}

	onResize() {
		if(this.toggle.classList.contains('is-transitioning')) {
			return
		} else {
			this.toggle.classList.add('is-transitioning')
			this.toggle.classList.remove('active')
			document.body.classList.remove('noscroll')
			this.links.classList.remove('active')
			this.dropdowns.forEach(dropdown => {
				dropdown.classList.remove('open')
			})
			var that = this
			setTimeout(function() {
				that.toggle.classList.remove('is-transitioning')
			}, 500)
		}
	}
	
	bindAll() {
		["subClicks", "toggleNav", "navigate", "toggleChildNav", "toggleChildNavBack", "onResize"].forEach((fn) => (this[fn] = this[fn].bind(this)));
	}

	init() {
		this.bindAll()
		this.setupChildNav()
		this.toggle.addEventListener('mousedown', () => {
			this.toggleNav()
		}, false)
		this.dropdowns.forEach(dropdown => {
			dropdown.addEventListener('mousedown', (e) => {
				if(window.innerWidth < 992) {
					e.preventDefault()
				}
				this.toggleChildNav(dropdown, e)
			})
		})
		this.backBtns.forEach(backBtn => {
			backBtn.addEventListener('mousedown', () => {
				this.toggleChildNavBack(backBtn)
			})
		})
		this.clickLinks.forEach(link => {
			link.addEventListener('mousedown', () => {
				this.navigate(link)
				this.toggleNav()
			})
		})
		window.addEventListener('resize', this.onResize, false)

	}
}

new MobileNav()