const SECONDS_PER_HUNDRED_PIXELS = 20

function updateNotificationSpeed() {
	const windowSize = window.innerWidth
	let textContainer = notificationBanner.querySelector('.notifications'),
		textWidth = textContainer.offsetWidth
		ratio =  textWidth / windowSize
	let speed = ratio * SECONDS_PER_HUNDRED_PIXELS
	textContainer.style.setProperty('--animationLength', speed + 's');
	textContainer.classList.add('animate')
}

const notificationBanner = document.querySelector('.notification_banner')
if(notificationBanner) {
	window.addEventListener('load', updateNotificationSpeed)
	window.addEventListener('resize', updateNotificationSpeed)
}