{
	const accordion = document.querySelectorAll('.question')
	accordion.forEach(function(el) {
		let title = el.querySelector('.accordion_question'),
			show = el.querySelector('.accordion_reveal')
		
		show.classList.add('js-active')
		title.addEventListener('click', function(el) {
			title.classList.toggle('active')
			if(show.style.maxHeight) {
				show.style.maxHeight = null
			} else {
				show.style.maxHeight = show.scrollHeight + 'px'
			}
		})
	})
}

function hideReveal() {
	const revealSections = document.querySelectorAll('.hide_reveal')

	revealSections.forEach(function(el) {
		let title = el.querySelector('.title'),
			content = el.querySelector('.content')
		
		content.classList.add('js-active')
		title.addEventListener('click', function(el) {
			title.classList.toggle('active')
			if(content.style.maxHeight) {
				content.style.maxHeight = null
			} else {
				content.style.maxHeight = content.scrollHeight + 'px'
			}
		})
	})

}

window.addEventListener('load', hideReveal, false);