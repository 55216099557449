function updateAffix() {

	let fixedHeader = document.querySelector('.fixed_header'),
		fixedHeaderPlaceholder = document.querySelector('.fixed_start'),
		fixedHeaderHeight = fixedHeader.offsetHeight,
		fixedHeaderDistance = document.querySelector('.top_header').getBoundingClientRect().height,
		fixedNotifs = document.querySelector('.notification_banner'),
		fixedNotifsPlaceholder = document.querySelector('.notification_banner_start'),
		fixedNotifsHeight = fixedNotifs ? fixedNotifs.getBoundingClientRect().height : 0,
		sideNav = document.querySelector('.side_navigation')
		fixedHeaderTop = 0

		if(fixedNotifs) {
			fixedNotifsPlaceholder.style.height = fixedNotifsHeight + 'px'
			fixedNotifs.classList.add('affix')
			fixedHeaderTop = fixedNotifsHeight
		}

		if(window.scrollY > fixedHeaderDistance) {
			fixedHeaderPlaceholder.style.height = fixedHeaderHeight + 'px'
			fixedHeader.style.top = fixedHeaderTop + 'px'
			fixedHeader.classList.add('affix')
		} else {
			fixedHeaderPlaceholder.style.height = '0px'
			fixedHeader.style.top = '0px'
			fixedHeader.classList.remove('affix')
		}
		if(sideNav) {
			sideNav.style.top = fixedHeaderHeight + 'px'
		}
}

window.addEventListener("load", updateAffix, false)
window.addEventListener("scroll", updateAffix, false)
window.addEventListener("resize", updateAffix, false)

function acfCheck() {
	acf.add_filter('validation_complete', function (json, $form) {
		return json;
	});
}
//window.addEventListener("load", acfCheck, false)