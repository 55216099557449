jQuery('.carousel_lion').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: false,
	fade: true,
	arrows : false,
	autoplay : true,
	autoplaySpeed: 3000,
	pauseOnHover: false,
	pauseOnFocus: false,
	speed: 1500,
	cssEase: 'ease-in-out'
});

jQuery('.carousel_sixth').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: false,
	fade: true,
	arrows : false,
	autoplay : true,
	autoplaySpeed: 3000,
	pauseOnHover: false,
	pauseOnFocus: false,
	speed: 1500,
	cssEase: 'ease-in-out'
});
